<template>

</template>

<script>
export default {
  name: "BackHome",
  data(){
    return{
      role:'',
    }
  },
  mounted() {
    this.role = window.sessionStorage.getItem("role");
    if (window.sessionStorage.getItem("role") === "designer"){
      this.$router.push('/PersonalBackHome');
    }else if (window.sessionStorage.getItem("role") === "enterprise") {
      this.$router.push('/CompanyBackHome');
    }
  }
}
</script>

<style scoped>

</style>